<script setup>
import { HUB } from '@/hub'
import { Gems } from "@/gems"
import { ref } from "@vue/reactivity"

import ImportUploadFile from "../components/ImportUploadFile"
import Menu from '../components/Menu'

const teams = ref(null);
const teamsFormation = ref(null);
const categories = ref(null);
const jobs = ref(null);
const genders = ref(null);
const users = ref(null);
const hierarchy = ref(null);
const hierarchyTable = ref(null);

function SelectFile(type) {
  this[`${type}`] = document.getElementById(type).files[0]
}

async function Import(type) {

  const fileInput = document.getElementById(type)
  const file = document.getElementById(type).files[0]

  if (!file) {
    fileInput.click()
    return
  }

  let formData = new FormData()
  formData.append("csv", file)

  const response = await HUB.CallBackEnd({
    action: HUB.Action(`import&type=${type}`),
    data: formData
  });

  if (response.error == 0) {
    const ws_res = await Gems.CallWS({ app: "kpi", action: "update-users-data" });

    if (response.data.excel) {

      if (ws_res?.data != true) {
        Gems.Telegram("Não foi possível gravar o histórico das equipas no CIRVALUE KPI", "tele-not-allowed");
      } else {
        Gems.Telegram(response.data.message, "tele-not-allowed")
      }

      Gems.ExportExcel(response.data.data)
    } else {
      Gems.Telegram(response.data, "tele-success")
    }
  }


  document.getElementById(type).value = null
  this[`${type}`] = null
}

function Export(type) {
  HUB.CallBackEnd({
    action: HUB.Action(`export&type=${type}`)
  })
    .then((res) => {
      if (res.error == 0) {
        Gems.ExportExcel(res.data);
      }
    })
    .catch(() => {
      Gems.Telegram(
        "Erro, favor contactar o administrador",
        "tele-not-allowed"
      );
    });
}

function ShowWarning(text, id) {
  const divWarning = document.getElementById(id);

  if (divWarning.children.length > 1) {
    window.CloseWarning(id);
  } else {
    const div = document.createElement("div");
    divWarning.appendChild(div);

    div.innerHTML += `<button class='fas fa-times close-import' onclick='CloseWarning(${id})'></button>`;
    div.innerHTML += '<li>Apenas importe ficheiros que foram exportados deste interface.</li>'
    div.innerHTML += '<li>Não apague colunas do EXCEL exportado.</li>'
    div.innerHTML += '<li>Os registos serão processados pela ordem em que aparecem no ficheiro.</li>'
    div.innerHTML += text;
    div.innerHTML += '<li>Grave o ficheiro com o seguinte formato:</li><br/>'
    div.innerHTML += "<div class='excel-img'>";
    div.setAttribute("class", "import-warning");
  }
}

window.CloseWarning = (id) => {
  document
    .getElementById(id)
    .removeChild(document.getElementById(id).lastElementChild);

  hierarchyTable.value.classList.remove('last-warning-opened')
}


</script>


<template>
  <div id="div-container" class="main-container-background">

    <Menu></Menu>


    <div id="div-page-container">

      <img class="inpeople-default-image" src="../images/inpeople.png">

      <div class="container-info-circle">
        <div class="info-circle field-unique"></div>
        <p class="info-text">
          Campos [obrigatórios] de dados únicos
        </p>
      </div>

      <div class="container-info-circle">
        <div class="info-circle field-not-modify"></div>
        <p class="info-text">
          Campos Key de dados únicos que não podem ser modificados depois de carregados na base de dados
        </p>
      </div>

      <div>
        <div id="2" class="warning">
          <div class="icon-container">
            <i class="fas fa-info-circle icon" @click="
              ShowWarning(`<li>Em nenhuma circunstância modifique a coluna 'Key' [coluna A].</li>
              <li>Dado nulo (vazio) na  [Coluna D] será interpretado como ' 0' (zero).</li>
              <li>Os dados NÃO deverão conter o caracter ';' (ponto e vírgula).</li></i>
              <br/>
              <li>Novo registo:</li>
              <ul>
                <li>[coluna A] precisa estar vazia.</li>
                <li>O valor da [coluna B] não existe na Base de Dados.</li>
              </ul>
              <li>Alteração [colunas B, C e D]:</li>
              <ul>
                <li>[coluna A] vazia e valor da [coluna B] existe na Base de Dados (Altera C e D).</li>
                <li>[coluna A] não vazia (Altera B, C e D).</li>
              </ul>
              <br>`, 2)
              ">
            </i>
            <span class="span-rule-text">Regras para importação</span>
          </div>
        </div>

        <div class="half">
          <div class="inputs-border div-btn-export">
            <button @click="Export('gender')" class="button clickable">
              <span>EXPORTAR GÉNEROS</span><span class="arrow">&#8595;</span>
            </button>
          </div>

          <table class="ex-table">
            <thead>
              <tr>
                <th class="field-not-modify">A. Key</th>
                <th>B. Código do género</th>
                <th>C. Descritor</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: 1234</td>
                <td>Ex.: A001</td>
                <td>Ex.: Feminino</td>
              </tr>
              <tr>
                <td>(campo numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
              </tr>
            </tbody>
          </table>
          <div class="upload-file">
            <ImportUploadFile :tableName="'genders'" :inputData="genders" @select-file="SelectFile($event)"
              @import="Import($event)" />
          </div>
        </div>

        <div class="half">
          <div class="inputs-border div-btn-export">
            <button @click="Export('teams')" class="button clickable">
              <span>EXPORTAR EQUIPAS</span><span class="arrow">&#8595;</span>
            </button>
          </div>

          <table class="ex-table">
            <thead>
              <tr>
                <th class="field-not-modify">A. Key</th>
                <th>B. Código da equipa</th>
                <th>C. Descritor</th>
                <th>D. Inativo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: 1234</td>
                <td>Ex.: A001</td>
                <td>Ex.: Coordenação</td>
                <td>Ex.: 1</td>
              </tr>
              <tr>
                <td>(campo numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo numérico)</td>
              </tr>
            </tbody>
          </table>
          <div class="upload-file">
            <ImportUploadFile :tableName="'teams'" :inputData="teams" @select-file="SelectFile($event)"
              @import="Import($event)" />
          </div>
        </div>
        <div class="half">
          <div class="inputs-border div-btn-export">
            <button @click="Export('categories')" class="button clickable">
              <span>EXPORTAR CATEGORIAS</span><span class="arrow">&#8595;</span>
            </button>
          </div>

          <table class="ex-table">
            <thead>
              <tr>
                <th class="field-not-modify">A. Key</th>
                <th>B. Código da categoria</th>
                <th>C. Descritor</th>
                <th>D. Inativo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: 1234</td>
                <td>Ex.: A001</td>
                <td>Ex.: Diretoria</td>
                <td>Ex.: 0</td>
              </tr>
              <tr>
                <td>(campo numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo numérico)</td>
              </tr>
            </tbody>
          </table>
          <div class="upload-file">
            <ImportUploadFile :tableName="'categories'" :inputData="categories" @select-file="SelectFile($event)"
              @import="Import($event)" />
          </div>
        </div>
        <div class="half">
          <div class="inputs-border div-btn-export">
            <button @click="Export('jobs')" class="button clickable">
              <span>EXPORTAR FUNÇÕES</span><span class="arrow">&#8595;</span>
            </button>
          </div>

          <table class="ex-table">
            <thead>
              <tr>
                <th class="field-not-modify">A. Key</th>
                <th>B. Código da função</th>
                <th>C. Descritor</th>
                <th>D. Valor/Hora</th>
                <th>D. Inativo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: 1234</td>
                <td>Ex.: A001</td>
                <td>Ex.: CEO</td>
                <td>Ex.: 20.50</td>
                <td>Ex.: 1</td>
              </tr>
              <tr>
                <td>(campo numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo numérico)</td>
                <td>(campo numérico)</td>
              </tr>
            </tbody>
          </table>
          <div class="upload-file">
            <ImportUploadFile :tableName="'jobs'" :inputData="jobs" @select-file="SelectFile($event)"
              @import="Import($event)" />
          </div>
        </div>

        <div>
          <div class="inputs-border div-btn-export">
            <button @click="Export('users')" class="button clickable">
              <span>EXPORTAR UTILIZADORES</span><span class="arrow">&#8595;</span>
            </button>
          </div>

          <table class="ex-table ex-table-users">
            <thead>
              <tr>
                <th class="field-not-modify">A. Key</th>
                <th class="field-unique">B. N° Interno</th>
                <th>C. Nome completo</th>
                <th>D. Nome profissional</th>
                <th>E. E-Mail</th>
                <th class="field-unique">F. Username</th>
                <th>G. Género<br>[Código]</th>
                <th>H. Categoria<br>[Código]</th>
                <th>I. Função<br>[Código]</th>
                <th>J. Ativo<br>(Global)</th>
                <th class="td-fade-out-first">K. Ativo<br>(App 1)</th>
                <th class="td-fade-out-second">L. Admin<br>(App 1)</th>
                <th class="td-fade-out-third">M. Ativo<br>(App 2)</th>
                <th class="td-fade-out-fourth">N. Admin<br>(App 2)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: 1234</td>
                <td>Ex.: A001</td>
                <td>Ex.: Manuel da Silva Costa</td>
                <td>Ex.: Manuel Silva</td>
                <td>Ex.: manuel.silva@mail.pt</td>
                <td>Ex.: Silva.manuel</td>
                <td>Ex.: 10A0</td>
                <td>Ex.: 200B</td>
                <td>Ex.: 3D00</td>
                <td class="td-fade-out-first">Ex.: 1</td>
                <td class="td-fade-out-second">Ex.: 1</td>
                <td class="td-fade-out-third">Ex.: 0</td>
                <td class="td-fade-out-fourth">Ex.: 0</td>
              </tr>
              <tr>
                <td>(campo numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
                <td>(campo boleano - 0 ou 1)</td>
                <td class="td-fade-out-first">(campo boleano - 0 ou 1)</td>
                <td class="td-fade-out-second">(campo boleano - 0 ou 1)</td>
                <td class="td-fade-out-third">(campo boleano - 0 ou 1)</td>
                <td class="td-fade-out-fourth">(campo boleano - 0 ou 1)</td>
              </tr>
            </tbody>
          </table>
          <div class="upload-file">
            <ImportUploadFile :tableName="'users'" :inputData="users" @select-file="SelectFile($event)"
              @import="Import($event)" />
          </div>
        </div>
      </div>

      <div class="half" ref="hierarchyTable">
        <div id="3" class="warning">
          <div class="icon-container">
            <i class="fas fa-info-circle icon" @click="
              hierarchyTable.classList.toggle('last-warning-opened')
            ShowWarning(`<li>Os dados NÃO deverão conter o caracter ';' (ponto e vírgula).</li>
            <li>A tabela inteira será substituída. Todos os registos que não existirem no ficheiro carregado serão perdidos.</li>`, 3);
            "></i>
            <span class="span-rule-text">Regras para importação</span>
          </div>
        </div>

        <div class="inputs-border div-btn-export">
          <button @click="Export('hierarchy')" class="button clickable">
            <span>EXPORTAR HIERARQUIA</span><span class="arrow">&#8595;</span>
          </button>
        </div>

        <table class="ex-table">
          <thead>
            <tr>
              <th>A. N° interno da chefia</th>
              <th>B. N° interno do subordinado</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ex.: A001</td>
              <td>Ex.: A020</td>
            </tr>
            <tr>
              <td>(campo alfa-numérico)</td>
              <td>(campo alfa-numérico)</td>
            </tr>
          </tbody>
        </table>
        <div class="upload-file">
          <ImportUploadFile :tableName="'hierarchy'" :inputData="hierarchy" @select-file="SelectFile($event)"
            @import="Import($event)" />
        </div>
      </div>
      <div class="half">
          <div class="inputs-border div-btn-export">
            <button @click="Export('teams-formation')" class="button clickable">
              <span>EXPORTAR CONSTIUIÇÃO DAS EQUIPAS</span><span class="arrow">&#8595;</span>
            </button>
          </div>

          <table class="ex-table">
            <thead>
              <tr>
                <th>A. Código da equipa</th>
                <th>B. Nº Interno</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ex.: A001</td>
                <td>Ex.: 0928213</td>
              </tr>
              <tr>
                <td>(campo alfa-numérico)</td>
                <td>(campo alfa-numérico)</td>
              </tr>
            </tbody>
          </table>
          <div class="upload-file">
            <ImportUploadFile :tableName="'teams-formation'" :inputData="teamsFormation" @select-file="SelectFile($event)"
              @import="Import($event)" />
          </div>
        </div>

    </div>

  </div>
</template>

<style scoped>
#div-container {
  display: flex;
  font-family: "Space Grotesk", sans-serif;
}

#div-page-container {
  padding: 25px 2%;
  flex-grow: 1;
}

.container-info-circle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.info-text {
  font-size: .9em;
  margin: 0px 5px;
  font-weight: 300;
}

.upload-file {
  display: flex;
  margin-bottom: 5px;
  margin-top: 5px;
  align-items: center;
}

.ex-table {
  width: 100%;
  height: 100px;
  text-align: center;
  word-wrap: break-word;
  border-collapse: separate;
  border: none;
  border-spacing: 0;
  background: #231f20;
  border-radius: 7px;
}

.ex-table thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-collapse: separate;
}


.ex-table-users {
  border: none;
  position: relative;
}

.ex-table-users::before {
  border-radius: 7px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mask-image: linear-gradient(270deg, #231f20, rgba(0, 0, 0, 0) 3%);
  -webkit-mask-image: linear-gradient(270deg, #231f20, rgba(0, 0, 0, 0) 3%);
  border: none;
  background-color: black;
  z-index: 1;
}

.ex-table thead tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.ex-table thead:first-child tr:first-child th:first-child,
.ex-table tbody:first-child tr:first-child td:first-child {
  border-radius: 7px 0 0 0;
}

.ex-table thead:last-child tr:last-child th:first-child,
.ex-table tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 7px;
}

.ex-table th,
.ex-table tbody td {
  text-align: left;
  vertical-align: top;
}

.ex-table tbody td {
  border-top: 1px solid black;
}

.ex-table tbody td:not(:first-child) {
  border-left: 1px solid black;
}

.half {
  width: 50% !important;
  margin-bottom: 20px;
}

.ex-table td,
.ex-table th {
  height: 30px;
}

.ex-table thead th,
.ex-table tbody td {
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
}

.ex-table thead th {
  font-weight: bold;
  overflow: hidden;
  text-transform: uppercase;
}

.ex-table thead th:not(:first-child) {
  border-left: 1px solid black;
}

.div-btn-export {
  margin-bottom: 5px;
}

.inputs-border div-btn-export:not(:first-of-type) {
  margin-top: 30px;
}

.inputs-border div-btn-export:first-of-type {
  margin-top: 10px;
}

.warning {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  margin-bottom: 5px;
}

.icon-container {
  display: flex;
  align-items: center;
}

.icon-container>span {
  font-weight: bold;
}

.icon {
  font-size: 1em;
  cursor: pointer;
  margin-right: 5px;
}

.field-not-modify {
  background-color: #FF4500;
}

.field-unique {
  background-color: rgba(24, 232, 255, 0.7);
}
</style>

<style>
.blink-import {
  animation: blink-import 1s step-start infinite;
}

@keyframes blink-import {
  50% {
    background: #00000040;
    color: black !important;
  }

  100% {
    background: black;
    color: var(--medium-gray-color) !important;
  }
}


.import-warning {
  background-color: #9600bbaa;
  text-align: initial;
  box-shadow: 0 0 10px 10px #ffffff11;
  height: 475px !important;
  min-height: 20px;
  max-width: 610px;
  min-width: 290px;
  cursor: default;
  font-size: .8em;
  font-weight: 300;

  backdrop-filter: blur(10px);
  position: absolute;
  left: 30px;
  top: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 10;
}

.import-warning li {
  width: 95%;
}

.close-import {
  position: absolute !important;
  top: 4px;
  right: 15px;
  font-size: 1.3em !important;
  cursor: pointer;
  border: none !important;
  width: 10px !important;
  height: 10px !important;
  background-color: transparent;
}

.close-import:hover {
  color: var(--default-app-hover-color) !important;
  position: absolute;
  background-color: transparent !important;
}

.excel-img {
  display: block;
  height: 380px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../images/excel.png");
}

.span-rule-text {
  font-size: .8em;
}

.td-fade-out-first {
  -webkit-mask: linear-gradient(90deg, #000, #0009);
  mask: linear-gradient(90deg, #000, #0009);
}

.td-fade-out-second {
  -webkit-mask: linear-gradient(90deg, #0009, #0007);
  mask: linear-gradient(90deg, #0009, #0007);
}

.td-fade-out-third {
  -webkit-mask: linear-gradient(90deg, #0006, #0004);
  mask: linear-gradient(90deg, #0006, #0004);
}

.td-fade-out-fourth {
  -webkit-mask: linear-gradient(90deg, #0003, #0001);
  mask: linear-gradient(90deg, #0003, #0001);
}

.arrow {
  margin-left: 5px;
  font-size: 1em;
}

.last-warning-opened {
  margin-bottom: 250px !important;
}
</style>
