<script setup>
import { onMounted, ref } from 'vue'
import { Gems } from "../gems"
import { HUB } from '../hub'
import store from "../store"
import Menu from '../components/Menu'

const files = ref([])

function AttachDoc() {
    document.getElementById("attach-document").click()
}

async function Send() {
    if (HUB.RequiredFieldsValid()) {
        Gems.Telegram("Comunicar este problema?", "tele-info", "", "").then(
            async () => {
                const btn = document.getElementById("btn-send")
                btn.innerText = "Aguarde..."
                btn.setAttribute("disabled", true)
                btn.style.cursor = "default"

                const subject = document.getElementById("txt-subject")
                const description = document.getElementById("txt-description")
                const formData = new FormData()

                formData.append(
                    "input",
                    JSON.stringify({
                        application: "HUB",
                        subject: subject.value,
                        description: description.value,
                        user_name: store.getters.User.name,
                        user_email: store.getters.User.email,
                    })
                )

                files.value.forEach((file) =>
                    formData.append("documents[]", file)
                )

                try {
                    const response = await Gems.CallWS({
                        method: "post",
                        app: "intime",
                        action: "send-task",
                        body: formData,
                    })
                    if (response.status !== 200) {
                        Gems.Telegram("Não foi possível comunicar o problema", "tele-not-allowed")
                        return
                    }

                    Gems.Telegram("Recebemos a sua solicitação. Responderemos o mais brevemente possível", "tele-info", "")
                    document.getElementById("ddl-types").value = 1
                    subject.value = ""
                    description.value = ""
                    files.value = []
                    document.getElementById("attach-document").value = ""

                } finally {
                    btn.innerText = "ENVIAR"
                    btn.removeAttribute("disabled")
                    btn.style.cursor = "pointer"
                }
            },
            () => { }
        );
    }
}

function RemoveFile(i) {
    files.value.splice(i, 1)
}

onMounted(() => {
    Gems.Tooltip(
        document.getElementById("i-help-icon"),
        'Recomendamos que leia a informação disponibilizada na documentação de "Ajuda"',
        "tooltip"
    )

    document
        .getElementById("attach-document")
        .onchange = (e) => {
            files.value.push(...e.target.files)
            e.target.value = ""
        }
})

</script>


<template>
    <div id="div-container" class="main-container-background">
        <Menu></Menu>
        <div class="page-container">
            <img class="inpeople-default-image" src="../images/inpeople.png">

            <div class="div-right">
                <div class="div-task">

                    <div class="lbl">
                        Pedido de ajuda
                        <i id="i-help-icon" class="fa fa-info-circle help-icon"></i>
                    </div>

                    <div class="div-filters">
                        <div class="div-label-container">
                            <div class="div-label label">TIPO:</div>
                        </div>
                        <div class="div-inputs">
                            <select name="ddl-types" id="ddl-types" class="dll-types" required>
                                <option value="">Selecione o tipo de problema</option>
                                <option value="">Dificuldade técnica</option>
                                <option value="">Dúvida na utilização/navegação</option>
                            </select>
                        </div>
                    </div>

                    <div class="div-filters">
                        <div class="div-label-container">
                            <div class="div-label label">ASSUNTO:</div>
                        </div>
                        <div class="div-inputs">
                            <input id="txt-subject" name="txt-subject" type="text" class="txt-input" required />
                        </div>
                    </div>

                    <div class="div-filters">
                        <div class="div-label-container div-description">
                            <div class="div-label-desc label">DESCRIÇÃO:</div>
                        </div>
                        <div colspan="2" class="div-inputs div-textarea">
                            <textarea id="txt-description" name="txt-description" type="text" class="txt-area"
                                required></textarea>
                        </div>
                    </div>

                    <div class="div-filters">
                        <div class="btns inputs-border div-inputs" colspan="2">
                            <button id="btn-send" type="button" class="button" @click="Send()">
                                ENVIAR
                            </button>
                        </div>
                    </div>

                    <div class="div-document">
                        <div class="btns inputs-border div-inputs" colspan="2">
                            <input type="file" id="attach-document" class="hidden" multiple />
                            <button id="btn-attach-document" @click="AttachDoc()" class="button">
                                ANEXAR FICHEIRO
                            </button>
                        </div>
                    </div>

                    <div class="div-filters">
                        <div class="div-label-container"></div>
                        <div colspan="2" class="div-inputs">
                            <div id="doc-list">
                                <div v-for="(file, i) in files" :key="i" class="div-file">
                                    <span class="file-name">{{ file.name }}</span>
                                    <i class="fas fa-times remove-file cursor-pointer" @click="RemoveFile(i)"
                                        @mouseover="Gems.Tooltip($event.target, 'Remover', 'tooltip')"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
#div-container {
    display: flex;
    min-height: 100vh;
}

.page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 35px 25px 14px;
}

.div-content {
    text-align: center;
    width: 100%;
    margin-top: 50px;
}

.div-label-container {
    text-align: right;
    width: 30%;
}

.label {
    font-size: 10pt;
    padding-right: 10px;
    display: inline-block;
}

.lbl {
    font-size: 20pt;
    font-weight: bold;
    margin: 0 0 30px 30px;
    text-align: left;
    opacity: .3;
}

.dll-types {
    width: 100%;
    padding: 10px;
    border-radius: 7px;
}

.txt-area {
    width: 100%;
    resize: vertical;
    height: 250px;
    border: 1px solid var(--medium-gray-color);
    padding: 10px;
    border-radius: 7px;
}

.txt-input {
    width: 100%;
    padding: 10px;
    border-radius: 7px;
    color: initial !important;
}

.div-description {
    align-self: start !important;
}

.div-label-desc {
    margin-top: 13px;
}

.btns {
    text-align: left;
    margin-bottom: 5px;
}

.div-task {
    display: flex;
    flex-direction: column;
    border-spacing: 5px;
    width: 60%;
    gap: 5px;
}

.help-icon {
    font-size: .7em;
}

.div-filters {
    display: flex;
    align-items: center;
    justify-content: end;
}

.div-inputs {
    width: 70%;
    min-width: 367px;
}

.div-inputs input {
    border: none;
}

.div-textarea {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
}

.div-document {
    display: flex;
    justify-content: end;
}

.div-right {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15%;
}

.div-file {
    display: flex;
    justify-content: space-between;
    text-align: left;
    cursor: default;
    align-items: center;
    width: 100%;
}

.break-word {
    word-break: break-all;
}

.remove-file {
    color: orangered;
    cursor: pointer;
}

.file-name {
    font-size: 0.7em;
    font-weight: 600;
}
</style>