import { Gems } from '@/gems'
import router from '@/router'
import store from './store';

class HUB {
  static ERROR_MESSAGE_GENERIC = "Contacte o administrador";

  static CheckCredentials() {
    window.addEventListener(
      "visibilitychange",
      async function () {
        if (!document.hidden && router.currentRoute.value.meta.requiresAuth == true) {
          await HUB.CallBackEnd({ action: HUB.Action("check-credentials") });
        }
      },
      false
    );
  }

  static async CallBackEnd({
    action,
    data = {},
    onSend = null,
    onComplete = null,
    options = { CUSTOM_VALIDATION: false }
  }) {
    const fetchOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      },
      body: data instanceof FormData ? data : new URLSearchParams({ input: JSON.stringify(data) }).toString()
    }

    Object.assign(fetchOptions, options)

    if (data instanceof FormData)
      delete fetchOptions.headers // let browser set the appropriate headers when send files

    onSend?.()

    let response = await fetch(action, fetchOptions)
    response = await response.json()

    if (response.redirect_to) {
      window.location.href = response.redirect_to
      return
    }

    if (response.data?.logged_user_id)
      sessionStorage.setItem('logged_user_id', response.data?.logged_user_id)

    if (response.data?.status != undefined) {
      HUB.StatusHandler(response)
    }

    if (response.error == 1 && !options.CUSTOM_VALIDATION) { // CUSTOM_VALIDATION here grant us the power to use telegram on our own directly with the code whoose make the request
      Gems?.Telegram(response.msg, "tele-not-allowed");
    }

    onComplete?.()
    return response
  }

  static StatusHandler(response) {
    let url = window.parent.location.origin

    switch (response.data?.status) {
      case 0:
        if (window.parent.location.pathname != "/") {
          url += `/`
          window.parent.history.pushState({ change: true, message: `Sessão expirada` }, "", url)
          window.parent.history.go()
        }
        break;
      case 1:
        window.parent.location.href = `hub`;
        break;
      case 2:
      case 3:
        url += `/hub`;

        window.parent.history.pushState(
          { change: true, message: `Nova sessão detetada` },
          "",
          url
        );
        window.parent.history.go();
        break;

      case 4:
        url += `/logout`;
        window.parent.history.pushState({ change: true, message: `Utilizador inativo` }, '', url);
        window.parent.history.go();
        break;
    }
    return;
  }

  static Action(uri) {
    const loggedUserId = sessionStorage.getItem('logged_user_id')

    if (loggedUserId)
      uri += `&logged_user_id=${loggedUserId}`

    return `../php/transaction.php?action=${uri}`
  }

  static isAdmin() {
    return store.getters?.User?.admin ?? false
  }

  static ContinueCircleProccess(elem) {
    const circleClickedId = Number(elem.id.split('-')[1])

    const logoAdmin = document.getElementById('logo-inpeople-admin')
    const logo = document.getElementById('logo-inpeople')
    if (elem.getAttribute('app-name') == 'hub') {
      if (!HUB.isAdmin())
        return false

      logoAdmin.style.display = 'block'
      logo.style.display = 'none'
      logo.parentElement.style.opacity = 1
    } else {
      logoAdmin.style.display = 'none'
      logo.style.display = ''
      logo.parentElement.style.opacity = .3
    }

    if (circleClickedId == 1 && elem.getAttribute('app-name') == 'intime')
      window.open(`https://${Gems.EnvPrefix()}intime.inpeoplehub.com/private/hub`, '_blank')

    if (circleClickedId == 1)
      return false

    return true
  }

  static GetCirclesOrdered() {
    return Array.from(document.querySelectorAll('.circle')).sort((a, b) => {
      if (Number(a.id.split('-')[1]) > Number(b.id.split('-')[1]))
        return 1

      if (Number(a.id.split('-')[1]) == Number(b.id.split('-')[1]))
        return 0

      if (Number(a.id.split('-')[1]) < Number(b.id.split('-')[1]))
        return -1
    })
  }

  static RequiredFieldsValid() {
    var requiredFields = HUB.RequiredFields()
    if (requiredFields > 0) {
      if (requiredFields == 1) {
        Gems.Telegram("campo obrigatório", "tele-not-allowed")
        return false
      }

      Gems.Telegram(requiredFields + " campos obrigatórios", "tele-not-allowed")
      return false
    }
    return true
  }

  static RequiredFields() {
    var arrElems = [];
    var elems = document.getElementsByTagName("*");

    for (var i = 0; i <= elems.length; i++) {
      if (elems[i] != null) {
        if (
          elems[i].tagName == "INPUT" ||
          elems[i].tagName == "TEXTAREA" ||
          elems[i].tagName == "SELECT"
        ) {
          elems[i].style.outlineColor = "";
          elems[i].style.outlineStyle = "";

          if (elems[i].tagName == "INPUT" || elems[i].tagName == "TEXTAREA") {
            if (
              elems[i].hasAttribute("required") &&
              elems[i].value.trim().length == 0
            ) {
              HUB.ErrorOutline(elems[i]);
              arrElems.push(elems[i]);
            }
          } else {
            if (
              elems[i].hasAttribute("required") &&
              elems[i].selectedIndex == 0
            ) {
              HUB.ErrorOutline(elems[i]);
              arrElems.push(elems[i]);
            }
          }
        }
      }
    }

    return arrElems.length;
  }

  static ErrorOutline(elem) {
    elem.style.outlineStyle = "solid";
    elem.style.outlineWidth = "2px";
    elem.style.outlineColor = "orangered";
  }
}

export { HUB }