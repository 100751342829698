<script setup>

import HierarchyUsersList from '../components/HierarchyUsersList'
import Menu from '../components/Menu'

import { onMounted, ref, watch } from 'vue'
import { HUB } from '../hub'
import { Gems } from '../gems'
import { useStore } from "vuex"

const store = useStore()
let usersSearched = ref([])
let showAll = ref(false)

async function FilterUsers(search_text = "") {

    const response = await HUB.CallBackEnd({
        action: HUB.Action('get-hierarchy-users'),
        data: { search_text }
    })

    usersSearched.value = response.data
}

function Drag(e, user) {
    let selectedUsers = usersSearched.value.filter(user => user.selected)
    const inArray = selectedUsers.findIndex(elem => elem.user_id == user.user_id)
    if (inArray == -1)
        selectedUsers.push(user)

    const container = document.createElement('div')

    selectedUsers.forEach(us => {
        const user = document.createElement('div')
        const divData = document.createElement('div')
        const spanName = document.createElement('div')
        const spanJob = document.createElement('div')

        user.className = 'searched-user-data-drag'
        divData.className = 'user-txt'
        spanName.className = 'line-clamp line-clamp-1'
        spanJob.className = 'line-clamp line-clamp-1'

        spanName.innerHTML = us.name
        spanJob.innerHTML = us.job_name

        divData.style.color = 'black'

        divData.appendChild(spanName)
        divData.appendChild(spanJob)

        user.appendChild(divData)
        container.appendChild(user)
    })

    store.dispatch('CreateDragPreview', { e, container })

    container.style.width = '500px'

    e.dataTransfer.setData('arrUsers', JSON.stringify(selectedUsers))
    e.dataTransfer.setData('actual-headship', JSON.stringify(false))
}

async function DropCEO(e) {
    store.commit('insertCEO', JSON.parse(e.dataTransfer.getData('arrUsers')))
    await store.dispatch('InsertHierarchy', { user: JSON.parse(e.dataTransfer.getData('arrUsers')) })
    store.dispatch('GetHierarchy')
    FilterUsers(document.getElementById('input-search-users').value)
}

async function DropHierarchy({ user, e }) {
    const newHeadship = user.user_id
    const actualHeadship = JSON.parse(e.dataTransfer.getData('actual-headship'))
    const subordinates = JSON.parse(e.dataTransfer.getData('arrUsers'))

    store.dispatch('InsertHierarchy', { user, subordinates, newHeadship, actualHeadship })
    FilterUsers(document.getElementById('input-search-users').value)
}

onMounted(() => {
    store.dispatch('GetHierarchy')
    FilterUsers()
})

watch(showAll, (e) => {
    showAll.value = !showAll.value
    if (e)
        document.querySelectorAll(".fa-plus-square").forEach(elem => elem.click())
    else
        document.querySelectorAll(".fa-minus-square").forEach(elem => elem.click())
})

</script>

<template>
    <div id="div-container" class="main-container-background">

        <Menu></Menu>

        <div class="container">
            <img class="inpeople-default-image" src="../images/inpeople.png">

            <input type="text" id="input-search-users" class="input-search-users" @keyup="FilterUsers($event.target.value)"
                placeholder="Pesquisar utilizador..." />

            <div class="users-list scrollbar">
                <div v-for="(user, i) in usersSearched" :key="i">
                    <div class="searched-user-data" draggable="true" @dragend="store.dispatch('DeleteDragPreview')"
                        @dragstart="Drag($event, user)">
                        <input type="checkbox" class="chk-multiple-users" :checked="user.selected" 
                            @click="user.selected = !user.selected"
                            @mouseenter="Gems.Tooltip($event.target, 'Selecionar', 'tooltip')" />

                        <div class="user-txt" @mouseenter="Gems.Tooltip($event.target, `Nº ${user.internal}`, 'tooltip')">
                            <span class="line-clamp line-clamp-1" :class="{ 'inactive-user': !user.active }">{{ user.name
                            }}</span>
                            <span class="line-clamp line-clamp-1" :class="{ 'inactive-user': !user.active }">{{ user.job_name }}</span>
                            <span class="line-clamp line-clamp-1" :class="{ 'inactive-user': !user.active }" v-if="user.headship_name">[{{ user.headship_name
                            }}]</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="hierarchy-tree">

                <div class="ceos-drop-container">
                    <div class="ceos-drop-space" @dragover.prevent @drop="DropCEO($event)">
                        <div>
                            Arraste um utilizador para AQUI para torná-lo "CEO" [Nível mais alto da hierárquia]
                        </div>
                    </div>
                    <div class="show-all-container clickable" @click="showAll = !showAll">
                        <i class="icon fas" :class="{ 'fa-plus-square': !showAll, 'fa-minus-square': showAll }" />
                        <span>Expandir/Fechar tudo</span>
                    </div>
                </div>

                <div class="user-row">
                    <HierarchyUsersList :hierarchy="store.getters.Hierarchy" @drop-hierarchy="DropHierarchy($event)" />
                </div>

            </div>
        </div>
    </div>
</template>

<style scoped>
.searched-user-data,
.searched-user-data-drag {
    display: grid;
    grid-template-columns: 12px 97%;
    cursor: pointer;
    gap: 10px;
}

.searched-user-data-drag {
    grid-template-columns: 100%;
    width: 100%;
}

.inactive-user {
    opacity: 0.3;
}

.chk-multiple-users {
    margin: 0;
    transform: scale(1.5);
    cursor: pointer;
}

.user-txt {
    display: grid;
    grid-template-columns: 40% 55%;
    gap: 10px;
    color: var(--light-gray-color);
}
</style>

<style scoped>
#div-container {
    display: flex;
    min-height: 100vh;
}

.container {
    padding: 25px 14px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.inpeople-default-image {
    margin-bottom: 20px;
}

.users-list,
.ceos-drop-container,
.input-search-users {
    width: 70%;
    border-radius: 7px;
    border: none;
    font-size: .8em;
}

.users-list,
.input-search-users {
    background-color: #231f20;
}

.users-list {
    height: 150px;
    overflow: hidden auto;
    padding: 5px 10px;
}

.user-txt {
    display: grid;
    grid-template-columns: 35% 35% 30%;
    gap: 10px;
}

.hierarchy-tree {
    margin-top: 20px;
}

.ceos-drop-container {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
}

.ceos-drop-space {
    border-radius: 7px;
    padding: 2px;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 10px 2px #ffffff10;
    cursor: default;
    width: 79%;
    background-image: var(--input-gradient-border);
}

.ceos-drop-space div {
    background-color: black;
    border-radius: 5px;
    height: 34px;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.show-all-container {
    min-width: -moz-fit-content;
    min-width: fit-content;
    padding: 0 3% 0 20px;
}

.show-all-container i {
    margin-right: 10px;
}

.show-all-container:hover {
    color: var(--default-hover-color);
}

.user-row:only-child {
    padding: 1%;
}

.searched-user-data {
    display: grid;
    grid-template-columns: 12px 97%;
    cursor: move;
    gap: 10px;
}

.input-search-users {
    padding: 10px;
    margin-bottom: 10px;
    color: white;
}

.input-search-users::placeholder {
    color: white;
}

.chk-multiple-users {
    margin: 0;
    transform: scale(1.2);
    cursor: pointer;
}

.inactive {
    opacity: .3;
}

</style>
